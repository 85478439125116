import React, { useEffect, useState } from 'react';
import { ArrowBackIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, ModalBody, ModalFooter, PinInput, PinInputField, Text } from '@chakra-ui/react';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useAppDispatch } from '~/lib/hooks';
import { addNotification } from '~/lib/notification/slice';
import { useLazyAuthQuery, useLazyGetUserInfoQuery, useLazyVerifyQuery } from '~/lib/user/api';
import { updateUser } from '~/lib/user/slice';
import { createCookies } from '~/app/actions';

import styles from './pin-screen.module.css';

interface PinScreenProps {
    setOpenPinScreen: (value: boolean) => void;
    onClose: () => void;
    email: string;
    isTokenError: boolean;
}

export const PinScreen = ({ onClose, setOpenPinScreen, email, isTokenError }: PinScreenProps) => {
    const [pinError, setPinError] = useState('');
    const dispatch = useAppDispatch();
    const [triggerUserInfo] = useLazyGetUserInfoQuery();

    const [trigger] = useLazyVerifyQuery();
    const [triggerGetPin] = useLazyAuthQuery();
    const [timer, setTimer] = useState(180);
    const [errorCounter, setErrorCounter] = useState(0);

    const startTimer = () => {
        let counter = 180;
        const intervalId = setInterval(() => {
            setTimer((counter -= 1));
            if (counter === 0) {
                clearInterval(intervalId);
            }
        }, 1000);
    };

    const handlePinComplete = async (value: string) => {
        if (isTokenError) {
            dispatch(
                addNotification({
                    status: 'error',
                    title: 'Ошибка',
                    description: `Уже было совершено слишком много попыток, попробуйте еще раз через 30 минут`,
                }),
            );

            return;
        }
        const data = await trigger({ email, code: value });

        if (data.error) {
            setPinError('Неправильный код');
            setErrorCounter((prev) => prev + 1);
        }
        if (data.data) {
            await createCookies({
                name: 'token',
                value: data.data.data,
                secure: false,
                httpOnly: true,
            });

            const userInfo = await triggerUserInfo();

            if (userInfo.data) {
                dispatch(updateUser(userInfo?.data.data));
                dispatch(
                    addNotification({
                        status: 'success',
                        title: 'Успех',
                        description: `Вы успешно вошли в свой личный кабинет`,
                    }),
                );
            }

            onClose();
            setOpenPinScreen(false);
        }
    };

    const handleGetPin = async () => {
        setOpenPinScreen(true);

        const data = await triggerGetPin({ email });

        if (data.data) {
            dispatch(
                addNotification({
                    status: 'success',
                    title: 'Успех',
                    description: `Пин-код для входа отправлен на почту`,
                }),
            );
        }

        // @ts-expect-error типизация тела ошибки
        if (data.error?.data.error === 'Code already send') {
            dispatch(
                addNotification({
                    status: 'error',
                    title: 'Ошибка',
                    description: `Пин-код уже был отправлен на почту, проверьте еще раз`,
                }),
            );
        }
    };

    const handleBackToPhoneScreen = () => {
        setOpenPinScreen(false);
    };

    useEffect(() => {
        startTimer();
    }, []);

    useEffect(() => {
        const setTokenError = async () => {
            const date = new Date();
            await createCookies({
                name: 'token-error',
                value: 'true',
                expires: new Date(date.setMinutes(date.getMinutes() + 30)),
            });
        };

        if (errorCounter > 5) {
            setTokenError();
        }
    }, [errorCounter]);

    useEffect(() => {
        if (!isTokenError) {
            setErrorCounter(0);
        }
    }, [isTokenError]);

    return (
        <>
            <IconButton
                aria-label='Back to phone'
                icon={<ArrowBackIcon w='24px' h='24px' />}
                position={'absolute'}
                top={'0.5rem'}
                left={'0.5rem'}
                w='32px'
                h='32px'
                backgroundColor={'inherit'}
                color='rgba(207, 207, 207, 1)'
                onClick={handleBackToPhoneScreen}
            />
            <ModalBody>
                <TypographyCore variant='h3' className={`${styles['typography-center']} mb-8 mt-20`}>
                    Введите код из письма
                </TypographyCore>
                <TypographyCore color='secondary' view='regular' size='s' className={`${styles['typography-center']} mb-20`}>
                    Мы отправили вам письмо с кодом на {email}
                </TypographyCore>
                <Box justifyContent={'center'} textAlign={'center'}>
                    <PinInput otp placeholder='' isInvalid={!!pinError} errorBorderColor={'rgba(202, 50, 20, 1)'} onComplete={handlePinComplete}>
                        <PinInputField
                            mr='8px'
                            h='84px'
                            w={'20%'}
                            backgroundColor={'rgba(247, 247, 247, 1)'}
                            _focusVisible={{
                                border: '1px solid rgba(174, 198, 184, 1)',
                            }}
                            border='none'
                            fontWeight={600}
                            fontSize={'60px'}
                            borderRadius={'8px'}
                        />
                        <PinInputField
                            mr='8px'
                            h='84px'
                            w={'20%'}
                            borderRadius={'8px'}
                            _focusVisible={{
                                border: '1px solid rgba(174, 198, 184, 1)',
                            }}
                            border='none'
                            fontWeight={600}
                            fontSize={'60px'}
                            backgroundColor={'rgba(247, 247, 247, 1)'}
                        />
                        <PinInputField
                            mr='8px'
                            h='84px'
                            w={'20%'}
                            borderRadius={'8px'}
                            _focusVisible={{
                                border: '1px solid rgba(174, 198, 184, 1)',
                            }}
                            border='none'
                            fontWeight={600}
                            fontSize={'60px'}
                            backgroundColor={'rgba(247, 247, 247, 1)'}
                        />
                        <PinInputField
                            h='84px'
                            w={'20%'}
                            borderRadius={'8px'}
                            _focusVisible={{
                                border: '1px solid rgba(174, 198, 184, 1)',
                            }}
                            border='none'
                            fontWeight={600}
                            fontSize={'60px'}
                            backgroundColor={'rgba(247, 247, 247, 1)'}
                        />
                    </PinInput>
                    {pinError || isTokenError ? (
                        <Flex gap={1} mt='4px'>
                            <WarningIcon color='rgba(202, 50, 20, 1)' />
                            <Text fontSize='xs' color='rgba(202, 50, 20, 1)'>
                                {pinError} <br /> {isTokenError ? 'Вы сделали слишком много попыток, попробуйте через 30 минут' : null}
                            </Text>
                        </Flex>
                    ) : null}
                </Box>
            </ModalBody>
            {isTokenError ? null : (
                <ModalFooter flexDirection={'column'} justifyContent={'center'}>
                    {timer ? (
                        <TypographyCore color='heavy' view='medium' size='s' className={styles['typography-center']}>
                            Получить код повторно: {timer} сек.
                        </TypographyCore>
                    ) : (
                        <ButtonCore
                            view='ghost'
                            size='small'
                            onClick={() => {
                                handleGetPin();
                                startTimer();
                            }}
                        >
                            Получить код повторно
                        </ButtonCore>
                    )}
                </ModalFooter>
            )}
        </>
    );
};

import { ToastProps } from '@chakra-ui/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import { removeDuplicateNotifications } from './helpers';

export interface NotificationItem {
    status?: 'error' | 'loading' | 'info' | 'warning' | 'success' | undefined;
    title?: string;
    description?: string;
    id?: string;
}

export interface Notification {
    list: NotificationItem[];
}

const initialState: Notification = {
    list: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification(state, action: PayloadAction<NotificationItem>) {
            const newList = removeDuplicateNotifications(state.list, action.payload);

            state.list = [...newList, { ...action.payload, id: nanoid() }];
        },
        removeNotificationById(state, action: PayloadAction<ToastProps['id']>) {
            state.list = state.list.filter((item) => item.id !== action.payload);
        },
        resetNotifications() {
            return initialState;
        },
    },
});

export const { actions: notificationActions } = notificationSlice;
export const { addNotification, removeNotificationById, resetNotifications } = notificationActions;

export default notificationSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Icon, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import { getCookies } from '~/app/actions';

import { SympeeLogo } from '../../../public/sympee-logo';

import { EmailScreen } from './email-screen';
import { PinScreen } from './pin-screen';

interface ProfileModalProps {
    onClose: () => void;
}

export const ProfileModal = ({ onClose }: ProfileModalProps) => {
    const [openPinScreen, setOpenPinScreen] = useState(false);
    const [email, setEmail] = useState('');

    const [isTokenError, setIsTokenError] = useState(false);

    const handleCloseModal = () => {
        setOpenPinScreen(false);
        setEmail('');
        onClose();
    };

    useEffect(() => {
        getCookies('token-error')
            .then((item) => {
                if (item === 'true') {
                    setIsTokenError(true);
                } else {
                    setIsTokenError(false);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <Modal isOpen={true} closeOnOverlayClick={false} returnFocusOnClose={false} onClose={handleCloseModal} autoFocus={false} isCentered={true}>
            <ModalOverlay />
            <ModalContent pt='40px' pl='40px' pr='40px' pb='40px' borderRadius='20px'>
                <ModalHeader textAlign='center' pt={0}>
                    <Icon as={SympeeLogo} w={'177px'} h={'40px'} />
                </ModalHeader>
                <ModalCloseButton color='rgba(207, 207, 207, 1)' onClick={handleCloseModal} />
                {openPinScreen ? (
                    <PinScreen onClose={handleCloseModal} setOpenPinScreen={setOpenPinScreen} email={email} isTokenError={isTokenError} />
                ) : (
                    <EmailScreen setOpenPinScreen={setOpenPinScreen} setEmail={setEmail} email={email} isTokenError={isTokenError} />
                )}
            </ModalContent>
        </Modal>
    );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FulfilledAction, PendingAction } from '~/lib/hooks';
import type { City } from '~/lib/user/api';

export interface UserProfile {
    name: string | null;
    phone: string | null;
    email: string | null;
    birthday: string | null;
    address: City;
    isLoadingUser: boolean;
}

export const initialState: { data: UserProfile } = {
    data: {
        name: null,
        phone: null,
        email: null,
        birthday: null,
        isLoadingUser: true,
        address: {
            id: null,
            name: null,
            lat: null,
            lon: null,
            image: null,
        },
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserProfile>) => {
            state.data = action.payload;
        },
        updateUser: (state, action: PayloadAction<Partial<UserProfile>>) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };

            state.data.isLoadingUser = false;
        },
        resetUser: (state) => {
            state.data = initialState.data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher<PendingAction>(
                (action) => action.type.startsWith('user/') && action.type.endsWith('/pending'),
                (state) => {
                    state.data.isLoadingUser = true;
                },
            )
            .addMatcher<FulfilledAction>(
                (action) => action.type.startsWith('user/') && action.type.endsWith('/fulfilled'),
                (state) => {
                    state.data.isLoadingUser = false;
                },
            );
    },
});

export const { setUser, resetUser, updateUser } = userSlice.actions;

export default userSlice.reducer;

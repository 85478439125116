import { apiSlice } from '../rtk-query/api';

export const documentsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDocuments: builder.query<
            {
                data: {
                    oferta: string;
                    policy: string;
                    personal_data: string;
                };
            },
            void
        >({
            query: () => ({ url: `v1/documents` }),
            transformErrorResponse: () => 'Кажется, что-то пошло не так при получении документов. Попробуйте еще раз',
        }),
    }),
});

export const { useLazyGetDocumentsQuery } = documentsApi;

import { createIcon } from '@chakra-ui/icons';

export const YandexLogo = createIcon({
    displayName: 'YandexLogo',
    viewBox: '0 0 17 24',
    path: (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M11.926 3.21242H10.0684C6.66264 3.21242 4.87387 4.92125 4.87387 7.45008C4.87387 10.3208 6.11229 11.6536 8.65797 13.3624L10.7563 14.7636L4.70189 23.7516H0.195312L5.63063 15.7204C2.50017 13.499 0.745672 11.3461 0.745672 7.68932C0.745672 3.11001 3.94499 -6.23369e-06 10.0339 -6.23369e-06H16.0885V23.7516H11.926V3.21242Z'
                fill='#FC3F1D'
            />
        </svg>
    ),
});
